<template>
    <div class="hb-artist-review">
        <div class="review-totalPoint">
            <div class="review-totalPoint-text">
                {{ totalPoint }}
            </div>

            <StarRating class="review-totalPoint-stars" :size="15" :gutter="7.5" :value="Number(totalPoint)" />
        </div>

        <div class="review-divider" />

        <div class="review-graph">
            <div class="review-graph-ul">
                <template v-for="(row, key) in pointGraph">
                    <div :key="key" class="review-graph-item">
                        <div class="graph-item--bar">
                            <div
                                class="graph-item--bar--fill"
                                :style="{
                                    height: toPercentage(row.count / reviewCount),
                                }"
                            />
                        </div>

                        <div class="graph-item--text">{{ row.point }}점</div>
                    </div>
                </template>
            </div>
        </div>

        <template v-if="reviewItem.totalCount">
            <div class="review--items">
                <ul>
                    <template v-for="(row, key) in reviewItem.list">
                        <li :key="key">
                            {{ row.itemText }}
                            <small>{{ toPercentage(row.count / reviewItem.totalCount) }}</small>
                        </li>
                    </template>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
import StarRating from '@/components/common/StarRating.vue'

interface ReviewItem {
    /** 경험텍스트 */
    itemText: string;
    /** 경험 종류 */
    itemCategory: 'good' | 'bad';
    /** 수량 */
    count: number;
}

export default {
    components: {
        StarRating,
    },
    props: {
        artistData: null,
        reviewItemData: {
            type: Array,
            default: () => [],
        },
        reviewData: {
            type: Array,
            default: () => [],
        },
        reviewCountData: null,
    },
    data() {
        return {
            localReviewCountData: [],
        }
    },
    computed: {
        totalPoint() {
            return this.artistData?.pointCount
        },
        reviewCount() {
            return Number(this.artistData?.reviewCount ?? 0)
        },
        reviewItem() {
            const data: {
                totalCount: number,
                list: ReviewItem[],
            } = {
                totalCount: 0,
                list: [],
            }

            try {
                data.list = (this?.reviewItemData ?? [])
                    .map(row => {
                        row.count = Number(row?.count) || 0
                        data.totalCount += row.count
                        return row
                    })
                    .sort((a, b) => {
                        const diff = b.count - a.count

                        if (!diff) {
                            if (a.itemCategory === 'good') return -1
                            if (b.itemCategory === 'good') return 1
                            return 0
                        }

                        return diff
                    })
                    .slice(0, 3)
            } catch {
                return data
            }

            return data
        },
        pointGraph() {
            const reviewCountData = (this.reviewCountData || this.localReviewCountData)[0] || {}
            const maxPoint = 5
            const pointGraph = Array(maxPoint)
                .fill(0)
                .map((_, index) => {
                    const point = maxPoint - index
                    return {
                        point,
                        count: reviewCountData[`score${point}`] ?? 0,
                    }
                })

            return pointGraph
        },
    },
    watch: {
        artistData(artistData) {
            if (artistData?.artistidx) {
                this.getReviewData()
            }
        },
    },
    created() {
        if (!Array.isArray(this.reviewCountData)) {
            this.getReviewData()
        }
    },
    methods: {
        toPercentage(value: number) {
            return `${Math.round(value * 100)}%`
        },
        getReviewData() {
            if (!this.artistData?.artistidx) {
                return
            }

            const req = {
                method: 'get',
                url: `/artist/review/${this.artistData.artistidx}/0/20`,
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        const reviewCountData = data.response.reviewCountData || []

                        this.localReviewCountData = reviewCountData
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.hb-artist-review {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 4rem;

    .review-divider {
        width: 1px;
        height: 70px;
        background: #dadada;
        margin: 0 50px;
    }

    .review-totalPoint {
        .review-totalPoint-text {
            font-size: 5.5rem;
            font-weight: 700;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
        }

        .review-totalPoint-stars {
            margin-top: 10px;
        }
    }

    .review-graph {
        .review-graph-ul {
            display: flex;

            .review-graph-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 22.5px;

                .graph-item--bar {
                    margin-bottom: 1.5px;
                    position: relative;
                    width: 12px;
                    height: 60px;
                    background: #f7f7f7;

                    .graph-item--bar--fill {
                        position: absolute;
                        bottom: 0;
                        background: #f5d03a;
                        width: 100%;
                    }
                }

                .graph-item--text {
                    font-size: 15px;
                    line-height: 35px;
                    height: 30px;
                    color: #959595;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .review--items {
        margin-top: 25px;
        width: 92%;

        ul {
            li {
                font-size: 1.9rem;
                line-height: 25px;
                font-weight: 500;
                color: #000;
                margin-bottom: 8px;

                small {
                    font-size: 1.9rem;
                    color: #999;
                    float: right;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
